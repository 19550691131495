@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    font-family: "Arial", sans-serif;
    font-weight: 700;
    font-style: normal;
    overflow-x: hidden;
    overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* For Firefox */
  .scroll {
    scrollbar-width: none;
    -ms-overflow-style: none;  
  }

.header{
    @apply md:text-3xl text-xl dark:text-white relative before:absolute before:-left-6 before:top-0 before:h-full before:w-2 before:bg-sidebar_green
}
.container_all{
    @apply xl:px-40 lg:pl-32 lg:pr-28 md:px-5 py-2 mt-7 
}

.transition_all {
    @apply transition-all ease-in-out duration-500 
}

/* Add these styles to your global CSS */
code {
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
    font-family: 'Fira Code', monospace;
  }
  
  pre {
    background-color: #2d2d2d;
    padding: 16px;
    border-radius: 6px;
    color: white;
    font-family: 'Fira Code', monospace;
    overflow-x: auto;
  }
  .borderRadius{
    border-radius: 8px;
  }

  .typing-container {
    display: inline-block;
    white-space: wrap;
    overflow: hidden;
    border-right: 0.15em solid #333;
    animation: blink-caret 0.75s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #333 }
  }
  